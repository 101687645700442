let clickmap = function ($) {

    /**
     * Selection du region
     */
    let selectRegion = function () {
        $('body').on('click', '.map svg a', function (e) {
            e.preventDefault();

            let $inputAnswersUser = $body.find('#module .clickmap .answers-user');
            let $btnAnswers = $body.find('#module .clickmap .answers a');
            let $next = $body.find('#module > section.main > .content > .next');

            let $region = $(this);
            $region.toggleClass('selected');

            let answers = [];
            let inputAnswers = $('#module .clickmap input.answers').val();
            if (!inputAnswers || inputAnswers === '') return false;
            inputAnswers = inputAnswers.split(',');
            let $selected = $('.map svg .selected');

            $selected.each(function (k) {
                let id = $(this).attr('xlink:href');
                let title = $(this).attr('xlink:title');
                answers[parseInt(id)] = {};
                answers[parseInt(id)].id = parseInt(id);
                answers[parseInt(id)].title = title;

                if (k === $selected.length - 1) {
                    if (inputAnswers.length === 0) return false;
                    $inputAnswersUser.val(JSON.stringify(answers)).trigger('change');
                }
            });

            if ($selected.length > 0) {
                $btnAnswers.removeClass('disabled');
                $next.removeClass('disabled');
            } else {
                $btnAnswers.addClass('disabled');
                $next.addClass('disabled');
            }
        });
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '.clickmap .answers a', function (e) {
            e.preventDefault();
            let _this = this;
            if ($(_this).hasClass('disabled')) return false;
            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });
        });
    };

    return {
        init: function () {
            selectRegion();
            runAnswers();
        }
    };
}(jQuery);

$(document).ready(function () {
    clickmap.init();
});